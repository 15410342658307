<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Most Viewed Plots</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plots</a></li>
          <li class="breadcrumb-item active" aria-current="page">Most Viewed</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <ValidationObserver v-slot="{}" ref="form">
              <form @change="filterPlots" class="forms-sample">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="date_range" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="date_range" horizontal label="Date Range">
                        <date-picker v-model="date_range" style="width:100%;" @change="filterPlots" type="date" value-type="format" format="YYYY-MM-DD" range></date-picker>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="city_id" rules="">
                      <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                        <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider name="plan_no">
                      <b-form-group slot-scope="{ errors }" label-for="plan_no" horizontal label="Plan No">
                        <b-form-input type="text" id="plan_no" v-model="plan_no" placeholder="Plan No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="plot_no">
                      <b-form-group slot-scope="{ errors }" label-for="plot_no" horizontal label="Plot No">
                        <b-form-input type="text" id="plot_no" v-model="plot_no" placeholder="Plot No"></b-form-input>
                        <p>{{ errors[0] }}</p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider action="#">
                      <b-form-group class="form-group d-lg-flex" label="Per Page">
                        <b-form-input type="number" class="form-control" placeholder="perPage" v-model="paginate"></b-form-input>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
<!--            <h4 class="card-title">Plots</h4>-->
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">Plots</h4>
              <download-excel
                class="btn btn-success"
                :data="plots"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="plots.xlsx">
                Download Plots
              </download-excel>
            </div>
            <b-table :items="plots" :busy="isBusy" id="table-list" responsive :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(points)="data">
                <span>{{ (data.item.plan.points == null) ? "N/A" : data.item.plan.points }}</span>
              </template>
              <template v-slot:cell(plan_id)="data">
                <span>{{ (data.item.plan.id == null) ? "N/A" : data.item.plan.id }}</span>
              </template>
              <template v-slot:cell(district)="data">
                <span>{{ (data.item.plan.district == null) ? "N/A" : data.item.plan.district }}</span>
              </template>
              <template v-slot:cell(planNo)="data">
                <span>{{ (data.item.plan.planNo == null) ? "N/A" : data.item.plan.planNo }}</span>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
import { ValidationObserver } from 'vee-validate'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import helper from '../../util/helper.js'
import _ from 'lodash'

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      currentPage: 1,
      perPage: 0,
      paginate: 10,
      isBusy: true,
      date_range: "",
      start_date: "",
      end_date: "",
      plan_no: "",
      plot_no: "",
      city_id: "",
      fields: [
        { key: 'plot', sortable: true },
        { key: 'plan_id', sortable: true },
        { key: 'district', sortable: false},
        { key: 'planNo', sortable: true},
        { key: 'points', sortable: false},
        { key: 'total_views', sortable: false},
        { key: 'created_at', sortable: false},
      ],
      json_fields: {
        'ID': 'id',
        "Plot Number": "plot",
        'Classification': 'classification',
        'plan_id': 'plan_id',
        'Area Size': 'meter_sq'
      }
    };
  },
  components: {
    ValidationObserver,
    DatePicker
  },
  computed: {
    rows() {
      return this.$store.state.plot.most_viewed_meta.total
    },
    paginationPageSize() {
      return this.$store.state.plot.most_viewed_meta.paginate
    },
    plots() {
      return this.$store.state.plot.most_viewed
    },
    cities() {
      let cities = this.$store.getters['city/listCities']
      cities.unshift({value: "", text: "Select City"})
      return cities
    }
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.plot.most_viewed_meta.current_page)
        this.fetchPlots(this.currentPage)
    },
    date_range(newVal) {
      if(newVal[0] != null && newVal[1] != null) {
        this.start_date = newVal[0]
        this.end_date = newVal[1]
      }
      else {
        this.start_date = ""
        this.end_date = ""
      }
    }
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    filterPlots: _.debounce(function() {
      this.fetchPlots(this.currentPage)
    }, 1000),
    fetchCities() {
      this.isBusy = true

      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchCities", payload)
      .then(() => {
        this.isBusyFeedback = false
      })
    },
    fetchPlots(current_page = 1) {
      this.isBusy = true

      const payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
          start_date: this.start_date,
          end_date: this.end_date,
          plan_no: this.plan_no,
          plot_no: this.plot_no,
          city_id: this.city_id
        },
      }
      for (const [key, value] of Object.entries(payload.meta)) {
        if (value == "")
          delete payload.meta[key]
      }

      this.$store.dispatch("plot/mostViewedPlots", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    }
  },
  created() {
    this.fetchCities()
    this.fetchPlots()
 },
}
</script>
